import LeftMenu from "@comp/left_menu.vue";
import PublicHeader from "@comp/public_header.vue";
export default {
    name: "analysis_assess",
    data() {
        return {
            systems: [],
            menus: [
                // {
                //     id: '401',
                //     name: "统计分析",
                //     routerName: "statistic_analysis",
                //     className: "icon-tongjifenxi-01"
                // },
                // {
                //     id: '401',
                //     name: "监测年报",
                //     routerName: "annual_report",
                //     className: "icon-jiancenianbao-01"
                // },
                // {
                //     id: '403',
                //     name: "月度报告",
                //     routerName: "monthly_report",
                //     className: "icon-yuedubaogao-01"
                // },
                // {
                //     id: '402',
                //     name: "报告管理",
                //     routerName: "report_manage",
                //     className: "icon-baogaoguanli-01"
                // },
                // {
                //     id: '405',
                //     name: "模型管理",
                //     routerName: "model_manage",
                //     className: "icon-moxingguanli-01"
                // },
                {
                    id: "403",
                    name: "分析评估",
                    routerName: "analysis_assesss",
                    className: "icon-fenxipinggu-01",
                },
                {
                    id: "404",
                    name: "评估结果",
                    routerName: "assess_result",
                    className: "icon-pinggujieguo-01",
                },
            ],
            defaultIndex: 1,
            mainMenuIndex: "40",
        };
    },
    components: {
        LeftMenu,
        PublicHeader,
    },
    created() {
        let routeName = this.$route.name;
        switch (routeName) {
            case "statistic_analysis":
                this.defaultIndex = 0;
                break;
            case "annual_report":
                // this.defaultIndex = 1;
                this.defaultIndex = 0;
                break;
            case "monthly_report":
                this.defaultIndex = 2;
                break;
            case "report_manage":
                // this.defaultIndex = 3;
                this.defaultIndex = 1;
                break;
            case "model_manage":
                this.defaultIndex = 4;
                break;
            default:
                if (
                    routeName.includes("assess_target") ||
                    routeName.includes("assess_record") ||
                    routeName.includes("assess_config")
                ) {
                    // this.defaultIndex = 5;
                    this.defaultIndex = 2;
                } else if (routeName.includes("assess_result")) {
                    // this.defaultIndex = 6;
                    this.defaultIndex = 3;
                }
                break;
        }
    },
};